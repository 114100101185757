<template>
  <MainPanel>
    <a-card style="width: 100%; min-height: 100vh;">
      <a-row :gutter="24">
        <a-col :span="6" :offset="9">
          <a-tree :showLine="true"
                  v-model:expandedKeys="expandedKeys"
                  :tree-data="treeData"
                  style="font-size: larger; background: lightcyan; color: darkcyan"
          >
          </a-tree>
        </a-col>
      </a-row>
    </a-card>
  </MainPanel>
</template>

<script>
import MainPanel from "@/components/basic/MainPanel";
import {message} from "ant-design-vue";

import * as DeptApi from '@/api/DeptApi.js';

export default {
  name: 'Dept',
  components: {
    MainPanel
  },
  data() {
    return {
      treeData: [],
      expandedKeys: [],
    }
  },

  methods: {
    findDept() {
      DeptApi['findDeptApi']()
        .then((result) => {
          let code = result.data.code;
          let msg = result.data.msg;
          if (code === 1) {
            this.treeData = result.data.data['treeData'];
            this.expandedKeys = result.data.data['expandedKeys'];
          } else {
            message.error(msg);
          }

        })
        .catch((e) => {
          message.error(e.message);
        })
    },
  },

  created() {
    this.findDept();
  },

  mounted() {

  }
}
</script>
